export const LOAD_OUR_PRODUCTS_START = "LOAD_OUR_PRODUCTS_START"
export const LOAD_OUR_PRODUCTS_SUCCESS = "LOAD_OUR_PRODUCTS_SUCCESS"
export const LOAD_OUR_PRODUCTS_ERROR = "LOAD_OUR_PRODUCTS_ERROR"

export const GET_SINGLE_PRODUCT_START = 'GET_SINGLE_PRODUCT_START'
export const GET_SINGLE_PRODUCT_SUCCESS = 'GET_SINGLE_PRODUCT_SUCCESS'
export const GET_SINGLE_PRODUCT_ERROR = 'GET_SINGLE_PRODUCT_ERROR'

export const ADD_OUR_PRODUCT_START = 'ADD_OUR_PRODUCT_START'
export const ADD_OUR_PRODUCT_SUCCESS = 'ADD_OUR_PRODUCT_SUCCESS'
export const ADD_OUR_PRODUCT_ERROR = 'ADD_OUR_PRODUCT_ERROR'

export const DELETE_OUR_PRODUCT_START = 'DELETE_OUR_PRODUCT_START'
export const DELETE_OUR_PRODUCT_SUCCESS = 'DELETE_OUR_PRODUCT_SUCCESS'
export const DELETE_OUR_PRODUCT_ERROR = 'DELETE_OUR_PRODUCT_ERROR'


export const UPDATE_OUR_PRODUCT_START = 'UPDATE_OUR_PRODUCT_START'
export const UPDATE_OUR_PRODUCT_SUCCESS = 'UPDATE_OUR_PRODUCT_SUCCESS'
export const UPDATE_OUR_PRODUCT_ERROR = 'UPDATE_OUR_PRODUCT_ERROR'




