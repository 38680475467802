export const LOAD_APLLIED_JOB_START = "LOAD_APLLIED_JOB_START"
export const LOAD_APLLIED_JOB_SUCCESS = "LOAD_APLLIED_JOB_SUCCESS"
export const LOAD_APLLIED_JOB_ERROR = "LOAD_APLLIED_JOB_ERROR"

export const GET_SINGLE_APLLIED_START = 'GET_SINGLE_APLLIED_START'
export const GET_SINGLE_APLLIED_SUCCESS = 'GET_SINGLE_APLLIED_SUCCESS'
export const GET_SINGLE_APLLIED_ERROR = 'GET_SINGLE_APLLIED_ERROR'

export const DELETE_APLLIED_JOB_START = 'DELETE_APLLIED_JOB_START'
export const DELETE_APLLIED_JOB_SUCCESS = 'DELETE_APLLIED_JOB_SUCCESS'
export const DELETE_APLLIED_JOB_ERROR = 'DELETE_APLLIED_JOB_ERROR'





