export const LOAD_OUR_OPENING_START = "LOAD_OUR_OPENING_START"
export const LOAD_OUR_OPENING_SUCCESS = "LOAD_OUR_OPENING_SUCCESS"
export const LOAD_OUR_OPENING_ERROR = "LOAD_OUR_OPENING_ERROR"

export const GET_SINGLE_OPENING_START = 'GET_SINGLE_OPENING_START'
export const GET_SINGLE_OPENING_SUCCESS = 'GET_SINGLE_OPENING_SUCCESS'
export const GET_SINGLE_OPENING_ERROR = 'GET_SINGLE_OPENING_ERROR'

export const ADD_OUR_OPENING_START = 'ADD_OUR_OPENING_START'
export const ADD_OUR_OPENING_SUCCESS = 'ADD_OUR_OPENING_SUCCESS'
export const ADD_OUR_OPENING_ERROR = 'ADD_OUR_OPENING_ERROR'

export const DELETE_OUR_OPENING_START = 'DELETE_OUR_OPENING_START'
export const DELETE_OUR_OPENING_SUCCESS = 'DELETE_OUR_OPENING_SUCCESS'
export const DELETE_OUR_OPENING_ERROR = 'DELETE_OUR_OPENING_ERROR'


export const UPDATE_OUR_OPENING_START = 'UPDATE_OUR_OPENING_START'
export const UPDATE_OUR_OPENING_SUCCESS = 'UPDATE_OUR_OPENING_SUCCESS'
export const UPDATE_OUR_OPENING_ERROR = 'UPDATE_OUR_OPENING_ERROR'




