export const LOAD_CONTACT_US_START = "LOAD_CONTACT_US_START"
export const LOAD_CONTACT_US_SUCCESS = "LOAD_CONTACT_US_SUCCESS"
export const LOAD_CONTACT_US_ERROR = "LOAD_CONTACT_US_ERROR"

export const GET_SINGLE_CONTACT_START = 'GET_SINGLE_CONTACT_START'
export const GET_SINGLE_CONTACT_SUCCESS = 'GET_SINGLE_CONTACT_SUCCESS'
export const GET_SINGLE_CONTACT_ERROR = 'GET_SINGLE_CONTACT_ERROR'

export const DELETE_CONTACT_US_START = 'DELETE_CONTACT_US_START'
export const DELETE_CONTACT_US_SUCCESS = 'DELETE_CONTACT_US_SUCCESS'
export const DELETE_CONTACT_US_ERROR = 'DELETE_CONTACT_US_ERROR'





