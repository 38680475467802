
export const LOAD_OUR_BLOG_START = "LOAD_OUR_BLOG_START"
export const LOAD_OUR_BLOG_SUCCESS = "LOAD_OUR_BLOG_SUCCESS"
export const LOAD_OUR_BLOG_ERROR = "LOAD_OUR_BLOG_ERROR"

export const GET_SINGLE_BLOG_START = 'GET_SINGLE_BLOG_START'
export const GET_SINGLE_BLOG_SUCCESS = 'GET_SINGLE_BLOG_SUCCESS'
export const GET_SINGLE_BLOG_ERROR = 'GET_SINGLE_BLOG_ERROR'

export const ADD_OUR_BLOG_START = 'ADD_OUR_BLOG_START'
export const ADD_OUR_BLOG_SUCCESS = 'ADD_OUR_BLOG_SUCCESS'
export const ADD_OUR_BLOG_ERROR = 'ADD_OUR_BLOG_ERROR'

export const DELETE_OUR_BLOG_START = 'DELETE_OUR_BLOG_START'
export const DELETE_OUR_BLOG_SUCCESS = 'DELETE_OUR_BLOG_SUCCESS'
export const DELETE_OUR_BLOG_ERROR = 'DELETE_OUR_BLOG_ERROR'

export const UPDATE_OUR_BLOG_START = 'UPDATE_OUR_BLOG_START'
export const UPDATE_OUR_BLOG_SUCCESS = 'UPDATE_OUR_BLOG_SUCCESS'
export const UPDATE_OUR_BLOG_ERROR = 'UPDATE_OUR_BLOG_ERROR'
