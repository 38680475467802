export const LOAD_OUR_EMPLOYEES_START = "LOAD_OUR_EMPLOYEES_START"
export const LOAD_OUR_EMPLOYEES_SUCCESS = "LOAD_OUR_EMPLOYEES_SUCCESS"
export const LOAD_OUR_EMPLOYEES_ERROR = "LOAD_OUR_EMPLOYEES_ERROR"

export const GET_SINGLE_EMPLOYEE_START = 'GET_SINGLE_EMPLOYEE_START'
export const GET_SINGLE_EMPLOYEE_SUCCESS = 'GET_SINGLE_EMPLOYEE_SUCCESS'
export const GET_SINGLE_EMPLOYEE_ERROR = 'GET_SINGLE_EMPLOYEE_ERROR'

export const ADD_OUR_EMPLOYEE_START = 'ADD_OUR_EMPLOYEE_START'
export const ADD_OUR_EMPLOYEE_SUCCESS = 'ADD_OUR_EMPLOYEE_SUCCESS'
export const ADD_OUR_EMPLOYEE_ERROR = 'ADD_OUR_EMPLOYEE_ERROR'

export const DELETE_OUR_EMPLOYEE_START = 'DELETE_OUR_EMPLOYEE_START'
export const DELETE_OUR_EMPLOYEE_SUCCESS = 'DELETE_OUR_EMPLOYEE_SUCCESS'
export const DELETE_OUR_EMPLOYEE_ERROR = 'DELETE_OUR_EMPLOYEE_ERROR'


export const UPDATE_OUR_EMPLOYEE_START = 'UPDATE_OUR_EMPLOYEE_START'
export const UPDATE_OUR_EMPLOYEE_SUCCESS = 'UPDATE_OUR_EMPLOYEE_SUCCESS'
export const UPDATE_OUR_EMPLOYEE_ERROR = 'UPDATE_OUR_EMPLOYEE_ERROR'




