export const LOAD_CLIENTS_START = "LOAD_CLIENTS_START";
export const LOAD_CLIENTS_SUCCESS = "LOAD_CLIENTS_SUCCESS";
export const LOAD_CLIENTS_ERROR = "LOAD_CLIENTS_ERROR";

export const GET_SINGLE_CLIENTS_START = "GET_SINGLE_CLIENTS_START";
export const GET_SINGLE_CLIENTS_SUCCESS = "GET_SINGLE_CLIENTS_SUCCESS";
export const GET_SINGLE_CLIENTS_ERROR = "GET_SINGLE_CLIENTS_ERROR";

export const ADD_OUR_CLIENT_START = 'ADD_OUR_CLIENT_START'
export const ADD_OUR_CLIENT_SUCCESS = 'ADD_OUR_CLIENT_SUCCESS'
export const ADD_OUR_CLIENT_ERROR = 'ADD_OUR_CLIENT_ERROR'

export const DELETE_CLIENT_START = "DELETE_CLIENT_START";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_ERROR = "DELETE_CLIENT_ERROR";

export const UPDATE_CLIENTS_START = "UPDATE_CLIENTS_START";
export const UPDATE_CLIENTS_SUCCESS = "UPDATE_CLIENTS_SUCCESS";
export const UPDATE_CLIENTS_ERROR = "UPDATE_CLIENTS_ERROR";
