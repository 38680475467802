export const FORGOT_PASS_START = 'FORGOT_PASS_START'
export const FORGOT_PASS_SUCCESS = 'FORGOT_PASS_SUCCESS'
export const FORGOT_PASS_ERROR = 'FORGOT_PASS_ERROR'

export const CHANGE_PASS_START = 'CHANGE_PASS_START'
export const CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS'
export const CHANGE_PASS_ERROR = 'CHANGE_PASS_ERROR'

export const RESET_PASS_START = 'RESET_PASS_START'
export const RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS'
export const RESET_PASS_ERROR = 'RESET_PASS_ERROR'

