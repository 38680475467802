export const USER_REGISTER_START = "USER_REGISTER_START";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";

export const ADMIN_LOGIN_START = "ADMIN_LOGIN_START";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_ERROR = "ADMIN_LOGIN_ERROR";

export const LOAD_USERS_START = "LOAD_USERS_START";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_ERROR = "LOAD_USERS_ERROR";

export const UPDATE_USERS_START = "UPDATE_USERS_START";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_ERROR = "UPDATE_USERS_ERROR";

export const DELETE_USERS_START = "DELETE_USERS_START";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_ERROR = "DELETE_USERS_ERROR";

export const GET_SINGLE_USERS_START = "GET_SINGLE_USERS_START";
export const GET_SINGLE_USERS_SUCCESS = "GET_SINGLE_USERS_SUCCESS";
export const GET_SINGLE_USERS_ERROR = "GET_SINGLE_USERS_ERROR";

export const COUNT_START = "COUNT_START";
export const COUNT_SUCCESS = "COUNT_SUCCESS";
export const COUNT_ERROR = "COUNT_ERROR";