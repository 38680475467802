
// export const LOAD_MEDIAFILE_START = "LOAD_MEDIAFILE_START"
// export const LOAD_MEDIAFILE_SUCCESS = "LOAD_MEDIAFILE_SUCCESS"
// export const LOAD_MEDIAFILE_ERROR = "LOAD_MEDIAFILE_ERROR"
export const LOAD_OUR_SERVICES_START = "LOAD_OUR_SERVICES_START"
export const LOAD_OUR_SERVICES_SUCCESS = "LOAD_OUR_SERVICES_SUCCESS"
export const LOAD_OUR_SERVICES_ERROR = "LOAD_OUR_SERVICES_ERROR"

export const GET_SINGLE_SERVICE_START = 'GET_SINGLE_SERVICE_START'
export const GET_SINGLE_SERVICE_SUCCESS = 'GET_SINGLE_SERVICE_SUCCESS'
export const GET_SINGLE_SERVICE_ERROR = 'GET_SINGLE_SERVICE_ERROR'

export const DELETE_OUR_SERVICE_START = 'DELETE_OUR_SERVICE_START'
export const DELETE_OUR_SERVICE_SUCCESS = 'DELETE_OUR_SERVICE_SUCCESS'
export const DELETE_OUR_SERVICE_ERROR = 'DELETE_OUR_SERVICE_ERROR'

export const ADD_OUR_SERVICE_START = 'ADD_OUR_SERVICE_START'
export const ADD_OUR_SERVICE_SUCCESS = 'ADD_OUR_SERVICE_SUCCESS'
export const ADD_OUR_SERVICE_ERROR = 'ADD_OUR_SERVICE_ERROR'

export const UPDATE_OUR_SERVICE_START = 'UPDATE_OUR_SERVICE_START'
export const UPDATE_OUR_SERVICE_SUCCESS = 'UPDATE_OUR_SERVICE_SUCCESS'
export const UPDATE_OUR_SERVICE_ERROR = 'UPDATE_OUR_SERVICE_ERROR'




